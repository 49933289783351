import axios from "axios";

const baseUrl = "https://api.pc27services.com/api";
const getProducts = async (filter) => {
  try {
    var query = "?";
    Object.keys(filter).forEach((key) => {
      if (filter[key] === "" || !filter[key]) delete filter[key];
    });
    if (filter.categoryId === "all") delete filter.category;

    const paramsLength = Object.keys(filter).length;
    Object.keys(filter).forEach((key, index) => {
      const and = index + 1 === paramsLength ? "" : "&";
      query += `${key}=${filter[key]}${and}`;
    });
    const response = await axios.get(
      `${baseUrl}/products/getProducts.php${query}`
    );
    if (response.status !== 200) throw new Error("Invalid response");
    const { data, length, pageIndex, pageSize, max, min } = response.data;
    const canGoNext = length > (pageIndex + 1) * pageSize;
    return {
      data,
      canGoNext,
      max: +max,
      min: +min,
    };
  } catch (error) {
    console.log(error);
    return { data: [], canGoNext: false, max: 0, min: 0 };
  }
};

const getCategories = async () => {
  try {
    const response = await axios.get(`${baseUrl}/categories/getCategories.php`);
    const { data } = response.data;

    return data.map((elt) => {
      elt["parent"] = elt["categoryParent"];
      return elt;
    });
  } catch (error) {
    return [];
  }
};

const getProductById = async (id) => {
  try {
    const response = await axios.get(
      `${baseUrl}/products/productDetails.php?id=${id}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const postOrder = async (order) => {
  try {
    return axios.post("", order, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {}
};
export default {
  getProducts,
  getCategories,
  postOrder,
  getProductById,
};
