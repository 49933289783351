import axios from "axios";

const baseUrl = "https://api.pc27services.com/api";

const postContact = async (contact) => {
  try {
    const response = await axios.post(
      `${baseUrl}/Email/contactUs.php`,
      contact,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const postOrder = async (order) => {
  try {
    const response = await axios.post(`${baseUrl}/Email/sendOrder.php`, order, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

export default {
  postContact,
  postOrder,
};
